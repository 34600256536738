/* PRIMARIES */
$primary-purple: #280a54;
$primary-purple-filter: invert(18%) sepia(37%) saturate(2324%) hue-rotate(239deg) brightness(73%) contrast(120%);;
.primary-purple-text {
  color: #280a54;
}
.primary-purple-border {
  border-color: #280a54;
}
.primary-purple-background {
  background-color: #280a54;
}
.primary-purple-background::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(40 10 84 / var(--tw-bg-opacity, 1)) /* #280a54 */;
}

.peer:checked ~ .peer-checked\:primary-purple-background {
  --tw-bg-opacity: 1;
  background-color: rgb(40 10 84 / var(--tw-bg-opacity, 1));
}

.after\:primary-purple-background::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(40 10 84 / var(--tw-bg-opacity, 1));
}

$primary-purple-light: #8136db;
$primary-purple-light-filter: invert(31%) sepia(72%) saturate(6335%)
  hue-rotate(260deg) brightness(90%) contrast(90%);
.primary-purple-light-text {
  color: #8136db;
}
.primary-purple-light-background {
  background-color: #8036db;
}
.primary-purple-light-border {
  border-color: #8136db !important;
}

.peer:checked ~ .peer-checked\:primary-purple-background {
  --tw-bg-opacity: 1;
  background-color: rgb(128 54 219 / var(--tw-bg-opacity, 1));
}

$primary-green: #0dedab;
$primary-green-filter: invert(85%) sepia(79%) saturate(3974%) hue-rotate(81deg)
  brightness(96%) contrast(93%);
.primary-green-text {
  color: #0dedab;
}
.primary-green-background {
  background-color: #0dedab;
}

$primary-green-2: #e7fdf7;
$primary-green-filter-2: invert(99%) sepia(30%) saturate(1177%)
  hue-rotate(158deg) brightness(108%) contrast(98%);
.primary-green-2-text {
  color: #e7fdf7;
}
.primary-green-2-background {
  background: #e7fdf7;
}

$primary-purple-light-2: #f7f3fd;
$primary-purple-light-2-filter: brightness(0) saturate(100%) invert(88%)
  sepia(17%) saturate(106%) hue-rotate(224deg) brightness(102%) contrast(103%);
.primary-purple-light-2-text {
  color: #f7f3fd;
}
.primary-purple-light-2-background {
  background-color: #f7f3fd;
}

$primary-green-light: #e7fdf7;
$primary-green-light-filter: brightness(0) saturate(100%) invert(87%) sepia(21%)
  saturate(150%) hue-rotate(101deg) brightness(110%) contrast(98%);
.primary-green-light-text {
  color: #e7fdf7;
}

/* SECONDARIES */
$secondary-green: #06cb60;
$secondary-green-filter: invert(50%) sepia(72%) saturate(1193%)
  hue-rotate(108deg) brightness(100%) contrast(95%);
.secondary-green-text {
  color: #06cb60;
}

$secondary-green-light: #D8F4BE90;
$secondary-green-light-filter: invert(50%) sepia(72%) saturate(1193%)
hue-rotate(108deg) brightness(100%) contrast(98%);
.secondary-green-light-text {
  color: #D8F4BE90;
}
.secondary-green-light-background {
  background-color: #D8F4BE90;
}

$secondary-yellow: #f5c600;
$secondary-yellow-filter: invert(75%) sepia(46%) saturate(5901%)
  hue-rotate(18deg) brightness(116%) contrast(113%);
.secondary-yellow-text {
  color: #f5c600;
}
.secondary-yellow-border {
  border-color: #f5c600;
}

$secondary-orange-02: #e77230;
$secondary-orange-02-filter: invert(46%) sepia(31%) saturate(2011%)
  hue-rotate(345deg) brightness(107%) contrast(81%);
.secondary-orange-02-text {
  color: #e77230;
}

$secondary-orange-01: #ed8936;
$secondary-orange-01-filter: invert(57%) sepia(73%) saturate(453%)
  hue-rotate(343deg) brightness(93%) contrast(100%);
.secondary-orange-01-text {
  color: #ed8936;
}

$secondary-orange-light: #fdecd2;
$secondary-orange-light-filter: invert(100%) sepia(31%) saturate(2515%)
  hue-rotate(238deg) brightness(111%) contrast(99%);
.secondary-orange-light-text {
  color: #fdecd2;
}
.secondary-orange-light-background {
  background-color: #fdecd2;
}

$secondary-pink: #d83556;
$secondary-pink-filter: invert(46%) sepia(60%) saturate(5703%)
  hue-rotate(325deg) brightness(85%) contrast(99%);
.secondary-pink-text {
  color: #d83556;
}

$secondary-red: #e42313;
$secondary-red-filter: invert(21%) sepia(91%) saturate(5018%) hue-rotate(14deg)
  brightness(96%) contrast(109%);
.secondary-red-text {
  color: #e42313;
}
.secondary-red-border {
  border-color: #e42313;
}

$secondary-blue: #6083ff;
$secondary-blue-filter: invert(47%) sepia(93%) saturate(1917%)
  hue-rotate(208deg) brightness(99%) contrast(106%);
.secondary-blue-text {
  color: #6083ff;
}

/* NEUTRALS */
$neutrals-grey-06: #232530;
$neutrals-grey-06-filter: invert(13%) sepia(27%) saturate(486%)
  hue-rotate(192deg) brightness(86%) contrast(93%);
.neutrals-grey-06-text {
  color: #232530;
}
.neutrals-grey-06-background {
  background-color: #232530;
}

$neutrals-grey-05: #58585a;
$neutrals-grey-05-filter: invert(36%) sepia(0%) saturate(121%) hue-rotate(81deg)
  brightness(96%) contrast(101%);
.neutrals-grey-05-text {
  color: #58585a;
}
.neutrals-grey-05-background {
  background-color: #58585a;
}

$neutrals-grey-04: #848ea8;
$neutrals-grey-04-filter: invert(56%) sepia(2%) saturate(5436%)
  hue-rotate(196deg) brightness(97%) contrast(79%);
.neutrals-grey-04-text {
  color: #848ea8;
}
.neutrals-grey-04-background {
  background-color: #848ea8;
}

$neutrals-grey-03: #b7bdcc;
$neutrals-grey-03-filter: invert(95%) sepia(6%) saturate(1975%) hue-rotate(183deg) brightness(84%) contrast(88%);
.neutrals-grey-03-text {
  color: #b7bdcc;
}
.neutrals-grey-03-border {
  border-color: rgb(183, 189, 204);
}
.neutrals-grey-03-background {
  background-color: #b7bdcc;
}

$neutrals-grey-02: #e3e6eb;
$neutrals-grey-02-filter: invert(97%) sepia(4%) saturate(316%)
  hue-rotate(183deg) brightness(94%) contrast(95%);
.neutrals-grey-02-text {
  color: #e3e6eb;
}
.neutrals-grey-02-background {
  background-color: #e3e6eb;
}
.neutrals-grey-02-border {
  border-color: #e3e6eb;
}

$neutrals-grey-01: #f3f5fb;
$neutrals-grey-01-filter: invert(100%) sepia(17%) saturate(1389%)
  hue-rotate(177deg) brightness(100%) contrast(97%);
.neutrals-grey-01-text {
  color: #f3f5fb;
}
.neutrals-grey-01-background {
  background-color: #f3f5fb;
}

$neutrals-grey-00: #fcfdff;
$neutrals-grey-00-filter: invert(93%) sepia(6%) saturate(2785%)
  hue-rotate(187deg) brightness(104%) contrast(116%);
.neutrals-grey-00-text {
  color: #fcfdff;
}

$neutrals-white: #ffffff;
$neutrals-white-filter: invert(97%) sepia(1%) saturate(149%) hue-rotate(2deg)
  brightness(120%) contrast(100%);
.neutrals-white-text {
  color: #ffffff;
}
